<template>
  <vx-card style="padding-left: 10px">
    <!-- <vs-label style="font-weight: bold"> -->

    <!-- </vs-label> -->
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <!-- <vx-tooltip text="Add User" position="top" delay=".3s">
            <vs-button
              type="gradient"
              size="small"
              icon-pack="feather"
              icon="icon-user-plus"
              color="success"
              @click="addDetailHandler()"
            ></vs-button>
          </vx-tooltip>-->
          <div
            class="flex flex-wrap-reverse items-center data-list-btn-container"
          ></div>
          <v-select
            v-model="dataTableParams.limit"
            class="mr-4"
            :options="limitOptions"
            :clearable="false"
          />
        </div>

        <template slot="thead">
          <vs-th sort-key="name">Name</vs-th>
          <vs-th sort-key="email">Email</vs-th>
          <vs-th sort-key="lastName">Platform</vs-th>
          <vs-th sort-key="lastName">Type</vs-th>
          <vs-th sort-key="status">Renewal Status</vs-th>
          <vs-th sort-key="createdAt">Start Date</vs-th>
          <vs-th sort-key="createdAt">End Date</vs-th>
          <vs-th sort-key="action" size="25%">Actions</vs-th>
          <!-- <vs-th sort-key="updatedAt">Updated At</vs-th> -->
          <!--vs-th sort-key="action" size="25%">Actions</vs-th> -->
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].name">{{
              data[indextr].name ? data[indextr].name : ""
            }}</vs-td>
            <vs-td :data="data[indextr].email">{{ data[indextr].email }}</vs-td>
            <vs-td :data="data[indextr].lastName">{{
              data[indextr].platform == "ios" ? "Apple" : "Google"
            }}</vs-td>
            <vs-td :data="data[indextr].lastName">
              <div
                v-if="
                  data[indextr].productId ==
                  'com.ebpearls.tipquick.annual.subscription'
                "
              >
                Annual
              </div>
              <div
                v-else-if="
                  data[indextr].productId ==
                  'com.ebpearls.quicktip.trimonthly.subscription'
                "
              >
                Trimonthly
              </div>
              <div
                v-else-if="
                  data[indextr].productId ==
                  'com.ebpearls.quicktip.monthly.subscription'
                "
              >
                Monthly
              </div>
              <div v-else>Unknown Type</div>
            </vs-td>
            <vs-td :data="data[indextr].autoRenewStatus">{{
              data[indextr].autoRenewStatus
            }}</vs-td>
            <vs-td :data="data[indextr].purchaseDate">{{
              moment(data[indextr].purchaseDate).format("DD/MM/YYYY H:m:s")
            }}</vs-td>
            <vs-td :data="data[indextr].expiresDate">{{
              moment(data[indextr].expiresDate).format("DD/MM/YYYY H:m:s")
            }}</vs-td>
            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex">
                <vx-tooltip text="View" position="top" delay=".3s">
                  <!-- <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click="viewDetailHandler(data[indextr]._id)"
                    color="warning"
                  ></vs-button> -->
                  <feather-icon
                    icon="EyeIcon"
                    svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                    @click="viewDetailHandler(data[indextr]._id)"
                  />
                </vx-tooltip>
              </vs-row>
              <!-- <vs-button type="border" size="small" icon-pack="feather" icon="icon-send" color="success" class="mr-2"></vs-button> -->
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <span class="mr-2"
        >{{
          currentPage * dataTableParams.limit - (dataTableParams.limit - 1)
        }}
        -
        {{
          totalDocs - currentPage * dataTableParams.limit > 0
            ? currentPage * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}</span
      >
      <vs-pagination
        :total="totalPage"
        v-model="dataTableParams.page"
      ></vs-pagination>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10,
      },
      users: [],
      limitOptions: [5, 10, 25, 50, 100],
    };
  },
  methods: {
    ...mapActions("subscription", ["fetchSubscriptions"]),
    moment(date) {
      return moment(date);
    },
    getSubscriptions() {
      let self = this;
      this.fetchSubscriptions(self.dataTableParams).then((res) => {
        console.log("tocs");
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.currentPage = res.data.data.pagination.page;
        self.page = res.data.data.pagination.page;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getSubscriptions();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getSubscriptions();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getSubscriptions();
    },
    viewDetailHandler(id) {
      this.$router.push({ name: "subscriptionDetail", params: { id: id } });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getSubscriptions();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getSubscriptions();
      }
    },
  },
  mounted() {
    this.getSubscriptions();
  },
};
</script>
